export default
`To calculate the Net profit margin, first we need to calculate the *Net profit*.

##### Net profit
Is a measure of a firm's profitability that includes the results of financial activity of the enterprise.

**Net profit** = EBIT – Net financial costs

<br>

##### Net profit margin

is a measure of the economic performance of a sector or enterprise expressed in relative terms.
It is a difference between total income and all incurred costs (operating, capital and financial). Expressed in percentage.


<p align="center"><strong>(Net profit / Total Income) * 100</strong></p>`;